import { Mapper } from '../../04_infrastructure/base/mapper';
import { EmployeeInvitationType } from '../../04_infrastructure/types/employee-invitation-type';
import { EmployeeInvitationModel } from '../models/employee-invitation-model';

export class EmployeeInvitationMapper extends Mapper<
  EmployeeInvitationType,
  EmployeeInvitationModel
> {
  public mapFrom(param: EmployeeInvitationType): EmployeeInvitationModel {
    console.log(new Date(param.created_at).toLocaleTimeString());
    return {
      id: param.id,
      email: param.email,
      invitedAt: new Date(param.created_at),
    };
  }
  public mapTo(param: EmployeeInvitationModel): EmployeeInvitationType {
    throw new Error('Method not implemented.');
  }
  public mapFromList(
    param: EmployeeInvitationType[]
  ): EmployeeInvitationModel[] {
    return param.map(item => new EmployeeInvitationMapper().mapFrom(item));
  }
  public mapToList(param: EmployeeInvitationModel[]): EmployeeInvitationType[] {
    throw new Error('Method not implemented.');
  }
}
