import { Mapper } from '../../04_infrastructure/base/mapper';
import { EmployeeType } from '../../04_infrastructure/types/employee-type';
import { EmployeeModel } from '../models/employee-model';

export class EmployeeMapper extends Mapper<EmployeeType, EmployeeModel> {
  public mapFrom(param: EmployeeType): EmployeeModel {
    return {
      id: param.id,
      name: param.name,
      email: param.email,
    };
  }
  public mapTo(param: EmployeeModel): EmployeeType {
    throw new Error('Method not implemented.');
  }
  public mapFromList(param: EmployeeType[]): EmployeeModel[] {
    return param.map(item => new EmployeeMapper().mapFrom(item));
  }
  public mapToList(param: EmployeeModel[]): EmployeeType[] {
    throw new Error('Method not implemented.');
  }
}
