import { Mapper } from '../../04_infrastructure/base/mapper';
import { TenantUpdateType } from '../../04_infrastructure/types/tenant-update-type';
import { TenantUpdateModel } from '../models/tenant-update-model';

export class TenantUpdateMapper extends Mapper<
  TenantUpdateType,
  TenantUpdateModel
> {
  public mapFrom(param: TenantUpdateType): TenantUpdateModel {
    throw new Error('Method not implemented.');
  }
  public mapTo(param: TenantUpdateModel): TenantUpdateType {
    return {
      in_companyname: param.companyname,
    };
  }
  public mapFromList(param: TenantUpdateType[]): TenantUpdateModel[] {
    throw new Error('Method not implemented.');
  }
  public mapToList(param: TenantUpdateModel[]): TenantUpdateType[] {
    return param.map(item => new TenantUpdateMapper().mapTo(item));
  }
}
