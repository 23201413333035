import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantRepository } from '../../03_domain/repositories/tenant-repository';
import { EmployeeInvitationInfoType } from '../types/employee-invitation-info-type';
import { EmployeeInvitationType } from '../types/employee-invitation-type';
import { EmployeeType } from '../types/employee-type';
import { RegisterOrganisatorType } from '../types/register-organisator-type';
import { TenantType } from '../types/tenant-type';
import { TenantUpdateType } from '../types/tenant-update-type';

@Injectable()
export class TenantRepositoryImpl implements TenantRepository {
  constructor(private http: HttpClient) {}

  public registerTenant(
    organisator: RegisterOrganisatorType
  ): Observable<void> {
    return this.http
      .post<void>(
        environment.supabase.url +
          environment.supabase.edgePath +
          'register_user_v1',
        organisator
      )
      .pipe(
        map(result => {
          return;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public registerEmployee({
    invitationId,
    username,
    password,
  }: {
    invitationId: string;
    username: string;
    password: string;
  }): Observable<void> {
    return this.http
      .post<void>(
        environment.supabase.url +
          environment.supabase.edgePath +
          'register_employee_v1',
        {
          in_invitation_id: invitationId,
          in_username: username,
          in_password: password,
        }
      )
      .pipe(
        map(result => {
          return;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public inviteEmployee({
    email,
  }: {
    email: string;
  }): Observable<EmployeeInvitationType> {
    return this.http
      .post<EmployeeInvitationType>(
        environment.supabase.url +
          environment.supabase.edgePath +
          'invite_employee_v1',
        {
          in_email: email,
          in_redirect: `${environment.host}/auth/invitation`,
        }
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public getInvitationInfo({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<EmployeeInvitationInfoType> {
    return this.http
      .post<EmployeeInvitationInfoType[]>(
        environment.supabase.url +
          environment.supabase.rpcPath +
          'get_invitation_info_v1',
        {
          in_invitation_id: invitationId,
        }
      )
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('Invitation not found');
          }
          return result[0];
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public getEmployeeList(): Observable<EmployeeType[]> {
    return this.http
      .post<
        EmployeeType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'get_employee_list_v1', {})
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public deleteEmployee({
    employeeId,
  }: {
    employeeId: string;
  }): Observable<void> {
    return this.http
      .post<void>(
        environment.supabase.url +
          environment.supabase.edgePath +
          'delete_employee_v1',
        {
          in_employee_id: employeeId,
        }
      )
      .pipe(
        map(result => {
          return;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public getInvitationList(): Observable<EmployeeInvitationType[]> {
    return this.http
      .post<
        EmployeeInvitationType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'get_invitation_list_v1', {})
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public deleteInvitation({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<void> {
    return this.http
      .post<void>(
        environment.supabase.url +
          environment.supabase.rpcPath +
          'delete_invitation_v1',
        {
          in_invitation_id: invitationId,
        }
      )
      .pipe(
        map(result => {
          return;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public getTenantInfo(): Observable<TenantType> {
    return this.http
      .post<TenantType>(
        environment.supabase.url +
          environment.supabase.edgePath +
          'get_tenant_info_v1',
        {}
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  // TODO: Check correct implementation after implementing the endpoint
  public updateTenantInfo({
    tenantUpdate,
  }: {
    tenantUpdate: TenantUpdateType;
  }): Observable<void> {
    return this.http
      .post<void>(
        environment.supabase.url +
          environment.supabase.edgePath +
          'update_tenant_info_v1',
        tenantUpdate
      )
      .pipe(
        map(result => {
          return;
        }),
        catchError(error => {
          throw error;
        })
      );
  }
}
