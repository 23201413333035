import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EventRepository } from '../../03_domain/repositories/event-repository';
import { EventDetailsType } from '../types/event-details-type';
import { EventDetailsUpdateType } from '../types/event-details-update-type';
import { EventType } from '../types/event-type';
import { ParticipantDetailType } from '../types/participant-detail-type';
import { ParticipantUpdateType } from '../types/participant-update-type';

@Injectable()
export class EventRepositoryImpl implements EventRepository {
  constructor(private http: HttpClient) {}

  public getEventDetailsById(eventId: string): Observable<EventDetailsType> {
    return this.http
      .post<
        EventDetailsType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'get_event_details_v1', { in_id: eventId })
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('Event not found');
          }
          return result[0];
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  //TODO: change the rpc function and model and mappers
  public getEventById(eventId: string): Observable<EventType> {
    return this.http
      .post<
        EventType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'get_event_details_v1', { in_id: eventId })
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('Event not found');
          }
          return result[0];
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  public getTenantEventList(): Observable<EventDetailsType[]> {
    return this.http
      .post<
        EventDetailsType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'get_tenant_event_list_v1', {})
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('No Events found');
          }
          return result;
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  public createUpdateEvent(
    eventDetails: EventDetailsUpdateType
  ): Observable<EventDetailsType> {
    let functionName = eventDetails.in_id
      ? 'update_event_v1'
      : 'create_event_v1';

    return this.http
      .post<
        EventDetailsType[]
      >(environment.supabase.url + environment.supabase.rpcPath + functionName, eventDetails)
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('Error creating/updating event');
          }
          return result[0];
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  //TODO: change the rpc function
  public createUpdateEventParticipant(
    participant: ParticipantUpdateType
  ): Observable<ParticipantDetailType> {
    return this.http
      .post<
        ParticipantDetailType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'create_event_participant_v1', participant)
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('Error adding participant');
          }
          return result[0];
        }),
        catchError(error => {
          throw error;
        })
      );
  }

  public getEventParticipants(eventId: string): Observable<any> {
    return this.http
      .post<
        ParticipantDetailType[]
      >(environment.supabase.url + environment.supabase.rpcPath + 'get_event_participants_v1', { in_event_id: eventId })
      .pipe(
        map(result => {
          if (!result || result.length === 0) {
            throw new Error('Error getting participants');
          }
          return result;
        }),
        catchError(error => {
          throw error;
        })
      );
  }
}
