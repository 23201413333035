import { Mapper } from '../../04_infrastructure/base/mapper';
import { EmployeeInvitationInfoType } from '../../04_infrastructure/types/employee-invitation-info-type';
import { EmployeeInvitationInfoModel } from '../models/employee-invitation-info-model';

export class EmployeeInvitationInfoMapper extends Mapper<
  EmployeeInvitationInfoType,
  EmployeeInvitationInfoModel
> {
  public mapFrom(
    param: EmployeeInvitationInfoType
  ): EmployeeInvitationInfoModel {
    return {
      id: param.id,
      email: param.email,
      tenantName: param.tenant_name,
    };
  }
  public mapTo(param: EmployeeInvitationInfoModel): EmployeeInvitationInfoType {
    throw new Error('Method not implemented.');
  }
  public mapFromList(
    param: EmployeeInvitationInfoType[]
  ): EmployeeInvitationInfoModel[] {
    return param.map(item => new EmployeeInvitationInfoMapper().mapFrom(item));
  }
  public mapToList(
    param: EmployeeInvitationInfoModel[]
  ): EmployeeInvitationInfoType[] {
    throw new Error('Method not implemented.');
  }
}
