import { Mapper } from '../../04_infrastructure/base/mapper';
import { EventDetailsType } from '../../04_infrastructure/types/event-details-type';
import { EventDetailsModel } from '../models/event-details-model';

export class EventDetailsMapper extends Mapper<
  EventDetailsType,
  EventDetailsModel
> {
  public mapFrom(param: EventDetailsType): EventDetailsModel {
    return {
      id: param.id,
      name: param.name,
      location: param.location,
      description: param.description,
      tenantId: param.tenant_id,
      start: new Date(param.start),
      end: param.end ? new Date(param.end) : undefined,
      image: param.image,
      labelLogo: param.label_logo,
      hasTicketing: param.has_ticketing,
      hasNetworkingList: param.has_networking_list,
      hasRegistration: param.has_registration,
      photoRegulations: param.photo_regulations,
      registrationEnd: param.registration_end
        ? new Date(param.registration_end)
        : undefined,
      registrationLimit: param.registration_limit,
      createdAt: param.created_at,
    };
  }
  public mapTo(param: EventDetailsModel): EventDetailsType {
    throw new Error('Method not implemented.');
  }
  public mapFromList(param: EventDetailsType[]): EventDetailsModel[] {
    return param.map(item => new EventDetailsMapper().mapFrom(item));
  }
  public mapToList(param: EventDetailsModel[]): EventDetailsType[] {
    throw new Error('Method not implemented.');
  }
}
