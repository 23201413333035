import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from '@supabase/supabase-js';
import { Observable, filter, firstValueFrom, take } from 'rxjs';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Promise<GuardResult> => {
  const router = inject(Router);
  let userObservable: Observable<boolean | User | null>;
  const authService = inject(AuthService);
  userObservable = await authService.getCurrentUser().pipe(
    filter(val => val !== null),
    take(1)
  );
  let isAuthenticated = await firstValueFrom(userObservable).then(val => {
    return !!val;
  });

  if (isAuthenticated) {
    return true;
  } else {
    if (_state.url.startsWith('/events/')) {
      let eventId = _state.url.split('/')[2];
      return router.createUrlTree(['/event-registration/' + eventId]);
    }
    return router.createUrlTree(['/auth/login']);
  }
};
