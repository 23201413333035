import {
  ApplicationConfig,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { CookieService } from 'ngx-cookie-service';

import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MessageService } from 'primeng/api';
import {
  EventController,
  EventControllerImpl,
} from './02_controllers/event-controller';
import {
  TenantController,
  TenantControllerImpl,
} from './02_controllers/tenant-controller';
import { EventUsecases } from './02_controllers/usecases/event-usecases';
import { TenantUsecases } from './02_controllers/usecases/tenant-usecases';
import { EventRepository } from './03_domain/repositories/event-repository';
import { TenantRepository } from './03_domain/repositories/tenant-repository';
import { UserRepository } from './03_domain/repositories/user-repository';
import { EventUsecasesImpl } from './03_domain/usecases/event-usecases-impl';
import { TenantUsecasesImpl } from './03_domain/usecases/tenant-usecases-impl';
import { EventRepositoryImpl } from './04_infrastructure/repositories/event-repository-impl';
import { TenantRepositoryImpl } from './04_infrastructure/repositories/tenant-repository-impl';
import { UserRepositoryImpl } from './04_infrastructure/repositories/user-repository-impl';
import { AuthService } from './05_core/auth/auth.service';
import { authInterceptor } from './05_core/http/auth.interceptor';
import { errorInterceptor } from './05_core/http/error.interceptor';
import { PlatformService } from './05_core/platform/platform.service';
import { SEOService } from './05_core/seo/seo.service';
import { routes } from './app.routes';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideHotToastConfig(),
    provideHttpClient(
      withInterceptors([authInterceptor, errorInterceptor]),
      withFetch()
    ),
    MessageService,
    CookieService,
    AuthService,
    PlatformService,
    SEOService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideAnimationsAsync(),
    {
      provide: EventController,
      useClass: EventControllerImpl,
    },
    {
      provide: TenantController,
      useClass: TenantControllerImpl,
    },
    { provide: EventUsecases, useClass: EventUsecasesImpl },
    { provide: TenantUsecases, useClass: TenantUsecasesImpl },
    { provide: EventRepository, useClass: EventRepositoryImpl },
    { provide: TenantRepository, useClass: TenantRepositoryImpl },
    { provide: UserRepository, useClass: UserRepositoryImpl },
  ],
};
