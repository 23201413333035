import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EventUsecases } from '../../02_controllers/usecases/event-usecases';
import { EventDetailsMapper } from '../mapper/event-details-mapper';
import { EventDetailsUpdateMapper } from '../mapper/event-details-update-mapper';
import { EventMapper } from '../mapper/event-mapper';
import { ParticipantDetailMapper } from '../mapper/participant-detail-mapper';
import { ParticipantUpdateMapper } from '../mapper/participant-update-mapper';
import { EventDetailsModel } from '../models/event-details-model';
import { EventModel } from '../models/event-model';
import { ParticipantDetailModel } from '../models/participant-detail-model';
import { ParticipantUpdateModel } from '../models/participant-update-model';
import { EventRepository } from '../repositories/event-repository';

@Injectable()
export class EventUsecasesImpl implements EventUsecases {
  eventDetailsMapper = new EventDetailsMapper();
  eventMapper = new EventMapper();
  eventDetailsUpdateMapper = new EventDetailsUpdateMapper();
  participantDetailMapper = new ParticipantDetailMapper();
  participantUpdateMapper = new ParticipantUpdateMapper();

  constructor(private eventRepository: EventRepository) {}
  public getEventDetails(eventId: string): Observable<EventDetailsModel> {
    return this.eventRepository
      .getEventDetailsById(eventId)
      .pipe(map(this.eventDetailsMapper.mapFrom));
  }

  public getEvent(eventId: string): Observable<EventModel> {
    return this.eventRepository
      .getEventById(eventId)
      .pipe(map(this.eventMapper.mapFrom));
  }

  public getTenantEventList(): Observable<EventDetailsModel[]> {
    return this.eventRepository
      .getTenantEventList()
      .pipe(map(this.eventDetailsMapper.mapFromList));
  }

  public createUpdateEvent(
    eventDetails: EventDetailsModel
  ): Observable<EventDetailsModel> {
    return this.eventRepository
      .createUpdateEvent(this.eventDetailsUpdateMapper.mapTo(eventDetails))
      .pipe(map(this.eventDetailsMapper.mapFrom));
  }

  public getEventParticipants(
    eventId: string
  ): Observable<ParticipantDetailModel[]> {
    return this.eventRepository
      .getEventParticipants(eventId)
      .pipe(map(this.participantDetailMapper.mapFromList));
  }

  public createUpdateEventParticipant(
    participant: ParticipantUpdateModel
  ): Observable<ParticipantDetailModel> {
    return this.eventRepository
      .createUpdateEventParticipant(
        this.participantUpdateMapper.mapTo(participant)
      )
      .pipe(map(this.participantDetailMapper.mapFrom));
  }
}
