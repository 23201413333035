import { Routes } from '@angular/router';
import { authGuard } from './05_core/auth/auth.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./01_presentation/auth/auth-routing.module').then(
        m => m.AuthRoutingModule
      ),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./01_presentation/legal/legal-routing.module').then(
        m => m.LegalRoutingModule
      ),
  },
  {
    path: 'event-registration/:eventId',
    loadComponent: () =>
      import(
        './01_presentation/public-registration/public-registration.component'
      ).then(m => m.PublicRegistrationComponent),
  },
  {
    path: '',
    loadChildren: () =>
      import('./01_presentation/portal/portal-routing.module').then(
        m => m.PortalRoutingModule
      ),
    canActivate: [authGuard],
  },
  // Add a wildcard route for a 404 page if needed
  { path: '**', redirectTo: 'auth/login' },
];
