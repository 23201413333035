import { Observable } from 'rxjs';
import { EventDetailsType } from '../../04_infrastructure/types/event-details-type';
import { EventDetailsUpdateType } from '../../04_infrastructure/types/event-details-update-type';
import { EventType } from '../../04_infrastructure/types/event-type';
import { ParticipantDetailType } from '../../04_infrastructure/types/participant-detail-type';
import { ParticipantUpdateType } from '../../04_infrastructure/types/participant-update-type';

export abstract class EventRepository {
  public abstract getEventDetailsById(
    evenId: string
  ): Observable<EventDetailsType>;

  public abstract getEventById(evenId: string): Observable<EventType>;

  public abstract getTenantEventList(): Observable<EventDetailsType[]>;

  public abstract createUpdateEvent(
    eventDetails: EventDetailsUpdateType
  ): Observable<EventDetailsType>;

  public abstract getEventParticipants(
    eventId: string
  ): Observable<ParticipantDetailType[]>;

  public abstract createUpdateEventParticipant(
    participant: ParticipantUpdateType
  ): Observable<ParticipantDetailType>;
}
