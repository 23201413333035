import { Mapper } from '../../04_infrastructure/base/mapper';
import { ParticipantDetailType } from '../../04_infrastructure/types/participant-detail-type';
import { ParticipantDetailModel } from '../models/participant-detail-model';

export class ParticipantDetailMapper extends Mapper<
  ParticipantDetailType,
  ParticipantDetailModel
> {
  public mapFrom(param: ParticipantDetailType): ParticipantDetailModel {
    return {
      participantId: param.participant_id,
      userId: param.user_id,
      name: param.name,
      group: param.group,
      email: param.email,
      // TODO: Add status
      status: '',
      // TODO
      isRegistered: false,
      // TODO
      acceptedPhotoRegulations: false,
    };
  }
  public mapTo(param: ParticipantDetailModel): ParticipantDetailType {
    throw new Error('Method not implemented.');
  }
  public mapFromList(param: ParticipantDetailType[]): ParticipantDetailModel[] {
    return param.map(item => new ParticipantDetailMapper().mapFrom(item));
  }
  public mapToList(param: ParticipantDetailModel[]): ParticipantDetailType[] {
    throw new Error('Method not implemented.');
  }
}
