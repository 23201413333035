import { Mapper } from '../../04_infrastructure/base/mapper';
import { TenantType } from '../../04_infrastructure/types/tenant-type';
import { TenantModel } from '../models/tenant-model';

export class TenantMapper extends Mapper<TenantType, TenantModel> {
  public mapFrom(param: TenantType): TenantModel {
    return {
      id: param.id,
      companyname: param.companyname,
    };
  }
  public mapTo(param: TenantModel): TenantType {
    throw new Error('Method not implemented.');
  }
  public mapFromList(param: TenantType[]): TenantModel[] {
    return param.map(item => new TenantMapper().mapFrom(item));
  }
  public mapToList(param: TenantModel[]): TenantType[] {
    throw new Error('Method not implemented.');
  }
}
