import { Mapper } from '../../04_infrastructure/base/mapper';
import { EventDetailsUpdateType } from '../../04_infrastructure/types/event-details-update-type';
import { EventDetailsUpdateModel } from '../models/event-details-update-model';

export class EventDetailsUpdateMapper extends Mapper<
  EventDetailsUpdateType,
  EventDetailsUpdateModel
> {
  public mapFrom(param: EventDetailsUpdateType): EventDetailsUpdateModel {
    throw new Error('Method not implemented.');
  }

  public mapTo(param: EventDetailsUpdateModel): EventDetailsUpdateType {
    return {
      in_id: param.id,
      in_name: param.name,
      in_location: param.location,
      in_description: param.description,
      in_start: param.start
        ? this.getTimestampWithTimeZone(param.start)
        : undefined,
      in_end: param.end ? this.getTimestampWithTimeZone(param.end) : undefined,
      in_image: param.image,
      in_label_logo: param.labelLogo,
      in_has_ticketing: param.hasTicketing,
      in_has_networking_list: param.hasNetworkingList,
      in_photo_regulations: param.photoRegulations,
      in_has_registration: param.hasRegistration,
      in_registration_end: param.registrationEnd
        ? this.getTimestampWithTimeZone(param.registrationEnd)
        : undefined,
      in_registration_limit: param.registrationLimit,
    };
  }

  // create a function that gets a date and returns a timestamp with time zone
  public getTimestampWithTimeZone(date: Date): string {
    const timestamp = date.getTime();
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const timestampWithTimeZone = new Date(
      timestamp - timezoneOffset
    ).toISOString();
    return timestampWithTimeZone;
  }
  public mapFromList(
    param: EventDetailsUpdateType[]
  ): EventDetailsUpdateModel[] {
    throw new Error('Method not implemented.');
  }
  public mapToList(param: EventDetailsUpdateModel[]): EventDetailsUpdateType[] {
    return param.map(item => new EventDetailsUpdateMapper().mapTo(item));
  }
}
