import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserRepository } from '../../03_domain/repositories/user-repository';
import { UserAttributesUpdateType } from '../types/user-attributes-update-type';

@Injectable()
export class UserRepositoryImpl implements UserRepository {
  constructor(private http: HttpClient) {}

  public updateUserAttributes(
    userAttributes: UserAttributesUpdateType
  ): Observable<void> {
    console.log(userAttributes);
    return this.http
      .post<void>(
        environment.supabase.url +
          environment.supabase.rpcPath +
          'update_user_attributes_v1',
        userAttributes
      )
      .pipe(
        map(result => {
          return;
        }),
        catchError(error => {
          throw error;
        })
      );
  }
}
