import { Observable } from 'rxjs';

import { EmployeeInvitationInfoType } from '../../04_infrastructure/types/employee-invitation-info-type';
import { EmployeeInvitationType } from '../../04_infrastructure/types/employee-invitation-type';
import { EmployeeType } from '../../04_infrastructure/types/employee-type';
import { RegisterOrganisatorType } from '../../04_infrastructure/types/register-organisator-type';
import { TenantType } from '../../04_infrastructure/types/tenant-type';
import { TenantUpdateType } from '../../04_infrastructure/types/tenant-update-type';

export abstract class TenantRepository {
  public abstract registerTenant(
    organisator: RegisterOrganisatorType
  ): Observable<void>;

  public abstract registerEmployee({
    invitationId,
    username,
    password,
  }: {
    invitationId: string;
    username: string;
    password: string;
  }): Observable<void>;

  public abstract inviteEmployee({
    email,
  }: {
    email: string;
  }): Observable<EmployeeInvitationType>;

  public abstract getInvitationInfo({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<EmployeeInvitationInfoType>;

  public abstract getEmployeeList(): Observable<EmployeeType[]>;

  public abstract deleteEmployee({
    employeeId,
  }: {
    employeeId: string;
  }): Observable<void>;

  public abstract getInvitationList(): Observable<EmployeeInvitationType[]>;

  public abstract deleteInvitation({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<void>;

  public abstract getTenantInfo(): Observable<TenantType>;

  public abstract updateTenantInfo({
    tenantUpdate,
  }: {
    tenantUpdate: TenantUpdateType;
  }): Observable<void>;
}
