import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  let token = authService.getAccessToken();
  if (!token && req.url.includes('functions/v1')) {
    token = environment.supabase.key;
  }

  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  req = req.clone({
    setHeaders: {
      apikey: environment.supabase.key,
    },
  });

  return next(req);
};
