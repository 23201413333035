import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantUsecases } from '../../02_controllers/usecases/tenant-usecases';
import { EmployeeInvitationInfoMapper } from '../mapper/employee-invitation-info-mapper';
import { EmployeeInvitationMapper } from '../mapper/employee-invitation-mapper';
import { EmployeeMapper } from '../mapper/employee-mapper';
import { TenantMapper } from '../mapper/tenant-mapper';
import { TenantUpdateMapper } from '../mapper/tenant-update-mapper';
import { EmployeeInvitationInfoModel } from '../models/employee-invitation-info-model';
import { EmployeeInvitationModel } from '../models/employee-invitation-model';
import { EmployeeModel } from '../models/employee-model';
import { TenantModel } from '../models/tenant-model';
import { TenantUpdateModel } from '../models/tenant-update-model';
import { TenantRepository } from '../repositories/tenant-repository';

@Injectable()
export class TenantUsecasesImpl implements TenantUsecases {
  employeeInvitationInfoMapper = new EmployeeInvitationInfoMapper();
  employeeInvitationMapper = new EmployeeInvitationMapper();
  tenantMapper = new TenantMapper();
  tenantUpdateMapper = new TenantUpdateMapper();
  employeeMapper = new EmployeeMapper();
  constructor(private tenantRepository: TenantRepository) {}

  public registerTenant({
    email,
    password,
    username,
    companyName,
  }: {
    email: string;
    password: string;
    username: string;
    companyName: string;
  }): Observable<void> {
    return this.tenantRepository.registerTenant({
      in_email: email,
      in_password: password,
      in_username: username,
      in_companyname: companyName,
      in_redirect: `${environment.host}/auth/email-confirmed`,
    });
  }

  public registerEmployee({
    invitationId,
    username,
    password,
  }: {
    invitationId: string;
    username: string;
    password: string;
  }): Observable<void> {
    return this.tenantRepository.registerEmployee({
      invitationId,
      username,
      password,
    });
  }

  public inviteEmployee({
    email,
  }: {
    email: string;
  }): Observable<EmployeeInvitationModel | undefined> {
    return this.tenantRepository
      .inviteEmployee({
        email,
      })
      .pipe(map(this.employeeInvitationMapper.mapFrom));
  }

  public getInvitationInfo({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<EmployeeInvitationInfoModel> {
    return this.tenantRepository
      .getInvitationInfo({
        invitationId,
      })
      .pipe(map(this.employeeInvitationInfoMapper.mapFrom));
  }

  public getEmployeeList(): Observable<EmployeeModel[]> {
    return this.tenantRepository
      .getEmployeeList()
      .pipe(map(this.employeeMapper.mapFromList));
  }

  public deleteEmployee({
    employeeId,
  }: {
    employeeId: string;
  }): Observable<void> {
    return this.tenantRepository.deleteEmployee({
      employeeId,
    });
  }

  public getInvitationList(): Observable<EmployeeInvitationModel[]> {
    return this.tenantRepository
      .getInvitationList()
      .pipe(map(this.employeeInvitationMapper.mapFromList));
  }

  public deleteInvitation({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<void> {
    return this.tenantRepository.deleteInvitation({
      invitationId,
    });
  }

  public getTenantInfo(): Observable<TenantModel> {
    return this.tenantRepository
      .getTenantInfo()
      .pipe(map(this.tenantMapper.mapFrom));
  }

  public updateTenantInfo({
    tenantUpdate,
  }: {
    tenantUpdate: TenantUpdateModel;
  }): Observable<void> {
    return this.tenantRepository.updateTenantInfo({
      tenantUpdate: this.tenantUpdateMapper.mapTo(tenantUpdate),
    });
  }
}
