import { Mapper } from '../../04_infrastructure/base/mapper';
import { ParticipantUpdateType } from '../../04_infrastructure/types/participant-update-type';
import { ParticipantUpdateModel } from '../models/participant-update-model';

export class ParticipantUpdateMapper extends Mapper<
  ParticipantUpdateType,
  ParticipantUpdateModel
> {
  public mapFrom(param: ParticipantUpdateType): ParticipantUpdateModel {
    throw new Error('Method not implemented.');
  }
  public mapTo(param: ParticipantUpdateModel): ParticipantUpdateType {
    return {
      in_event_id: param.eventId,
      in_email: param.email,
      in_name: param.name,
      in_group_name: param.group,
    };
  }
  public mapFromList(param: ParticipantUpdateType[]): ParticipantUpdateModel[] {
    throw new Error('Method not implemented.');
  }
  public mapToList(param: ParticipantUpdateModel[]): ParticipantUpdateType[] {
    return param.map(item => new ParticipantUpdateMapper().mapTo(item));
  }
}
