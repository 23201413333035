import * as i0 from '@angular/core';
import { Injectable, Directive, Input, NgModule, createComponent, TemplateRef, Component, InjectionToken, inject, Injector, ApplicationRef, EnvironmentInjector, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
class TeleportService {
  constructor() {
    this.outlets = new BehaviorSubject('');
    this.asObservable = this.outlets.asObservable();
    this.ports = new Map();
  }
  outlet$(name) {
    return this.asObservable.pipe(filter(current => current === name), map(name => this.ports.get(name)));
  }
  newOutlet(name) {
    this.outlets.next(name);
  }
  static {
    this.ɵfac = function TeleportService_Factory(t) {
      return new (t || TeleportService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TeleportService,
      factory: TeleportService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class TeleportOutletDirective {
  constructor(vcr, service) {
    this.vcr = vcr;
    this.service = service;
  }
  ngOnChanges(changes) {
    // The `teleportOutlet` might be `null|undefined`, but we don't want nullable values to be used
    // as keys for the `ports` map.
    if (changes.teleportOutlet && typeof this.teleportOutlet === 'string') {
      this.service.ports.set(this.teleportOutlet, this.vcr);
      this.service.newOutlet(this.teleportOutlet);
    }
  }
  ngOnDestroy() {
    this.service.ports.delete(this.teleportOutlet);
  }
  static {
    this.ɵfac = function TeleportOutletDirective_Factory(t) {
      return new (t || TeleportOutletDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(TeleportService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TeleportOutletDirective,
      selectors: [["", "teleportOutlet", ""]],
      inputs: {
        teleportOutlet: "teleportOutlet"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportOutletDirective, [{
    type: Directive,
    args: [{
      selector: '[teleportOutlet]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: TeleportService
    }];
  }, {
    teleportOutlet: [{
      type: Input
    }]
  });
})();
class TeleportDirective {
  constructor(tpl, service) {
    this.tpl = tpl;
    this.service = service;
    this.subscription = null;
  }
  ngOnChanges(changes) {
    if (changes.teleportTo && typeof this.teleportTo === 'string') {
      this.dispose();
      this.subscription = this.service.outlet$(this.teleportTo).subscribe(outlet => {
        if (outlet) {
          this.viewRef = outlet.createEmbeddedView(this.tpl);
        }
      });
    }
  }
  ngOnDestroy() {
    this.dispose();
  }
  dispose() {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this.viewRef?.destroy();
  }
  static {
    this.ɵfac = function TeleportDirective_Factory(t) {
      return new (t || TeleportDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(TeleportService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TeleportDirective,
      selectors: [["", "teleportTo", ""]],
      inputs: {
        teleportTo: "teleportTo"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportDirective, [{
    type: Directive,
    args: [{
      selector: '[teleportTo]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: TeleportService
    }];
  }, {
    teleportTo: [{
      type: Input
    }]
  });
})();
class TeleportModule {
  static {
    this.ɵfac = function TeleportModule_Factory(t) {
      return new (t || TeleportModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TeleportModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TeleportModule, [{
    type: NgModule,
    args: [{
      declarations: [TeleportDirective, TeleportOutletDirective],
      exports: [TeleportDirective, TeleportOutletDirective]
    }]
  }], null, null);
})();
class CompRef {
  constructor(options) {
    this.options = options;
    if (options.vcr) {
      this.ref = options.vcr.createComponent(options.component, {
        index: options.vcr.length,
        injector: options.injector || options.vcr.injector
      });
    } else {
      this.ref = createComponent(options.component, {
        elementInjector: options.injector,
        environmentInjector: options.environmentInjector
      });
      options.appRef.attachView(this.ref.hostView);
    }
  }
  setInput(input, value) {
    this.ref.setInput(input, value);
    return this;
  }
  setInputs(inputs) {
    Object.keys(inputs).forEach(input => {
      this.ref.setInput(input, inputs[input]);
    });
    return this;
  }
  detectChanges() {
    this.ref.hostView.detectChanges();
    return this;
  }
  updateContext(context) {
    this.options.contextSignal?.set(context);
    return this;
  }
  appendTo(container) {
    container.appendChild(this.getElement());
    return this;
  }
  removeFrom(container) {
    container.removeChild(this.getElement());
    return this;
  }
  getRawContent() {
    return this.getElement().outerHTML;
  }
  getElement() {
    return this.ref.location.nativeElement;
  }
  destroy() {
    this.ref.destroy();
    !this.options.vcr && this.options.appRef.detachView(this.ref.hostView);
    this.ref = null;
  }
}
function isTemplateRef(value) {
  return value instanceof TemplateRef;
}
function isComponent(value) {
  return typeof value === 'function';
}
function isString(value) {
  return typeof value === 'string';
}
function getViewRef(value) {
  return value instanceof CompRef ? value.ref.hostView : value.ref;
}
class DynamicViewComponent {
  static {
    this.ɵfac = function DynamicViewComponent_Factory(t) {
      return new (t || DynamicViewComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DynamicViewComponent,
      selectors: [["dynamic-view"]],
      inputs: {
        content: "content"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "innerHTML"]],
      template: function DynamicViewComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("innerHTML", ctx.content, i0.ɵɵsanitizeHtml);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicViewComponent, [{
    type: Component,
    args: [{
      selector: 'dynamic-view',
      template: ` <div [innerHTML]="content"></div> `
    }]
  }], null, {
    content: [{
      type: Input
    }]
  });
})();
class TplRef {
  constructor(args) {
    this.args = args;
    if (this.args.vcr) {
      this.ref = this.args.vcr.createEmbeddedView(this.args.tpl, this.args.context || {}, {
        injector: args.injector
      });
      this.ref.detectChanges();
    } else {
      this.ref = this.args.tpl.createEmbeddedView(this.args.context || {}, args.injector);
      this.ref.detectChanges();
      this.args.appRef.attachView(this.ref);
    }
  }
  detectChanges() {
    this.ref.detectChanges();
    return this;
  }
  getElement() {
    const rootNodes = this.ref.rootNodes;
    if (rootNodes.length === 1 && rootNodes[0] === Node.ELEMENT_NODE) {
      this.element = rootNodes[0];
    } else {
      this.element = document.createElement('div');
      this.element.append(...rootNodes);
    }
    return this.element;
  }
  destroy() {
    if (this.ref.rootNodes[0] !== 1) {
      this.element?.parentNode.removeChild(this.element);
      this.element = null;
    }
    if (!this.args.vcr) {
      this.args.appRef.detachView(this.ref);
    }
    this.ref.destroy();
    this.ref = null;
  }
  updateContext(context) {
    this.ref.context = context;
    return this;
  }
}
class StringRef {
  constructor(value) {
    this.value = value;
  }
  getElement() {
    return this.value;
  }
  detectChanges() {
    return this;
  }
  updateContext() {
    return this;
  }
  destroy() {}
}
const VIEW_CONTEXT = new InjectionToken('Component context');
class ViewService {
  constructor() {
    this.injector = inject(Injector);
    this.appRef = inject(ApplicationRef);
    this.environmentInjector = inject(EnvironmentInjector);
  }
  createComponent(component, options = {}) {
    let injector = options.injector ?? this.injector;
    let contextSignal;
    if (options.context) {
      contextSignal = signal(options.context);
      injector = Injector.create({
        providers: [{
          provide: VIEW_CONTEXT,
          useValue: contextSignal.asReadonly()
        }],
        parent: injector
      });
    }
    return new CompRef({
      component,
      vcr: options.vcr,
      injector,
      appRef: this.appRef,
      environmentInjector: options.environmentInjector || this.environmentInjector,
      contextSignal
    });
  }
  createTemplate(tpl, options = {}) {
    return new TplRef({
      vcr: options.vcr,
      appRef: this.appRef,
      tpl,
      context: options.context,
      injector: options.injector
    });
  }
  createView(content, viewOptions = {}) {
    if (isTemplateRef(content)) {
      return this.createTemplate(content, viewOptions);
    } else if (isComponent(content)) {
      return this.createComponent(content, viewOptions);
    } else if (isString(content)) {
      return new StringRef(content);
    } else {
      throw 'Type of content is not supported';
    }
  }
  static {
    this.ɵfac = function ViewService_Factory(t) {
      return new (t || ViewService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ViewService,
      factory: ViewService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function injectViewContext() {
  return inject(VIEW_CONTEXT);
}
class DynamicViewDirective {
  constructor(defaultTpl, vcr, viewService) {
    this.defaultTpl = defaultTpl;
    this.vcr = vcr;
    this.viewService = viewService;
  }
  ngOnInit() {
    this.resolveContentType();
  }
  ngOnChanges(changes) {
    const viewChanged = changes.view && !changes.view.isFirstChange();
    const contextChanged = changes.context && !changes.context.isFirstChange();
    const inputsChanged = changes.inputs && !changes.inputs.isFirstChange();
    if (viewChanged) {
      this.resolveContentType();
    } else if (contextChanged) {
      this.viewRef.updateContext(this.context);
    } else if (isComponent(this.view) && inputsChanged) {
      this.viewRef.setInputs(this.inputs || {});
    }
  }
  resolveContentType() {
    this.viewRef?.destroy();
    if (isString(this.view)) {
      this.viewRef = this.viewService.createComponent(DynamicViewComponent, {
        vcr: this.vcr,
        injector: this.injector
      });
      this.viewRef.setInput('content', this.view).detectChanges();
    } else if (isComponent(this.view)) {
      this.viewRef = this.viewService.createComponent(this.view, {
        vcr: this.vcr,
        injector: this.injector ?? this.vcr.injector,
        context: this.context
      });
      if (this.inputs) {
        this.viewRef.setInputs(this.inputs);
      }
    } else {
      this.viewRef = this.viewService.createView(this.view || this.defaultTpl, {
        vcr: this.vcr,
        injector: this.injector ?? this.vcr.injector,
        context: this.context
      });
    }
  }
  ngOnDestroy() {
    this.viewRef?.destroy();
  }
  static {
    this.ɵfac = function DynamicViewDirective_Factory(t) {
      return new (t || DynamicViewDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(ViewService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DynamicViewDirective,
      selectors: [["", "dynamicView", ""]],
      inputs: {
        view: [0, "dynamicView", "view"],
        injector: [0, "dynamicViewInjector", "injector"],
        context: [0, "dynamicViewContext", "context"],
        inputs: [0, "dynamicViewInputs", "inputs"]
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicViewDirective, [{
    type: Directive,
    args: [{
      selector: '[dynamicView]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: ViewService
    }];
  }, {
    view: [{
      type: Input,
      args: ['dynamicView']
    }],
    injector: [{
      type: Input,
      args: ['dynamicViewInjector']
    }],
    context: [{
      type: Input,
      args: ['dynamicViewContext']
    }],
    inputs: [{
      type: Input,
      args: ['dynamicViewInputs']
    }]
  });
})();
class DynamicViewModule {
  static {
    this.ɵfac = function DynamicViewModule_Factory(t) {
      return new (t || DynamicViewModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DynamicViewModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicViewModule, [{
    type: NgModule,
    args: [{
      declarations: [DynamicViewDirective, DynamicViewComponent],
      exports: [DynamicViewDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of overview
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CompRef, DynamicViewDirective, DynamicViewModule, StringRef, TeleportDirective, TeleportModule, TeleportOutletDirective, TplRef, ViewService, getViewRef, injectViewContext, isComponent, isString, isTemplateRef };
